<template lang="pug">
    .main-wrapper.nps-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Pesquisa de Satisfação</b>

        Panel.my-2(header="Filtros" :toggleable="true" :collapsed="windowInnerWidth < 576" mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-3
                    label.form-label Estabelecimento:
                    ProgressBar(v-if='waitingFilters.estabelecimento' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='filters.cd_estabelecimento'
                        :options='options.estabelecimentos'
                        optionLabel='text'
                        showClear
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )
                .p-col-12.p-md-3
                    label.form-label Médico:
                    .p-inputgroup
                        Dropdown( v-model='filters.cd_especialista' showClear :options='options.especialistas' dataKey='value' :filter='options.especialistas.length > 0'
                            optionLabel='label' optionValue='value' placeholder='TODOS' @change='applyFilters()' )
                .p-col-12.p-md-3
                    label.form-label Forma de Resposta:
                    .p-inputgroup
                        Dropdown( v-model='filters.ie_tipo' showClear :options='options.tipo' dataKey='value'
                            optionLabel='label' optionValue='value' placeholder='TODOS' @change='applyFilters()' )
                .p-col-12.p-md-3
                    label.form-label Tipo:
                    .p-inputgroup
                        Dropdown( v-model='filters.ie_tipo_avaliado' showClear :options='options.tipo_avaliado' dataKey='value'
                            optionLabel='label' optionValue='value' placeholder='TODOS' @change='applyFilters()' )
                .p-col-12.p-md-3
                    label.form-label Nota:
                    .p-inputgroup
                        Dropdown( v-model='filters.nr_avaliacao' showClear :options='options.nota' dataKey='value'
                            optionLabel='label' optionValue='value' placeholder='TODOS' @change='applyFilters()' )
                        
                .p-col-6.p-md-3
                    label.form-label Data inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_criado_inicio' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_fim'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' @click='filters.dt_inicio = null; applyFilters()')

                .p-col-6.p-md-3
                    label.form-label Data final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_criado_fim' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_criado_inicio'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' @click='filters.dt_criado_fim = null; applyFilters()')
                .p-col-6.p-md-3
                    label.form-label <br/>
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')
        .ta-center.mb-2
            Button.mr-1(v-for='nota in avaliacao_geral' :key='nota.nr_avaliacao' :label='"Nota " + nota.nr_avaliacao + " : " + nota.total' :class='{" border-selected-avaliacao raiva": nota.nr_avaliacao == 1, "border-selected-avaliacao ansiedade": nota.nr_avaliacao == 2, "border-selected-avaliacao alegria": nota.nr_avaliacao == 3 ,"border-selected-avaliacao inveja": nota.nr_avaliacao == 4, "border-selected-avaliacao nojinho": nota.nr_avaliacao == 5}')
                span {{nota.nr_avaliacao}}
        ProgressBar(v-if="waiting" mode="indeterminate")
        div(v-else-if="! list.length")
            p.ta-center.text-secondary(style="margin-top: 40px;") Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style="position: relative")
                            .ta-left
                                p <b>Nome fantasia:</b> {{ props.data.nm_fantasia }}
                                p <b>Razão social:</b> {{ props.data.nm_razao_social }}
                                p <b>CNPJ:</b> {{ props.data.nr_cnpj_f }}
                                p <b>Telefone:</b> {{ props.data.nr_telefone_f }}

            Paginator.dataview(
                :rows="paginator.per_page"
                :first="(paginator.page - 1) * paginator.per_page"
                :totalRecords="paginator.count"
                @page="onPage($event)"
            )

            Panel.datatable(header="Lista de Empresas")
                Paginator.mb-1(
                    :rows="paginator.per_page"
                    :first="(paginator.page - 1) * paginator.per_page"
                    :totalRecords="paginator.count"
                    @page="onPage($event)"
                )
                DataTable(:value="list")
                    Column(headerStyle="width: 12%;" field="horario" header="Data" bodyStyle="text-align: center")
                        template(#body='props')
                            span {{ props.data.dt_agenda_f }}
                    Column(headerStyle="width: 15%;" header="Forma de Resposta" bodyStyle="text-align: center")
                        template(#body='props')
                            p(v-if='props.data.pergunta.ie_tipo == "C"') Comentário
                            p(v-if='props.data.pergunta.ie_tipo == "N"') Nota
                    Column(headerStyle="width: 15%;" header="Tipo" bodyStyle="text-align: center")
                        template(#body='props')
                            p(v-if='props.data.pergunta.ie_tipo_avaliado == "ME"') Médico
                            p(v-if='props.data.pergunta.ie_tipo_avaliado == "ES"') Estabelecimento
                            p(v-if='props.data.pergunta.ie_tipo_avaliado == "NA"') Não Aplicável
                    Column(headerStyle="width: 8%;" field="nr_avaliacao" header="Nota" bodyStyle="text-align: center")
                    Column(headerStyle="width: 20%" field="estabelecimento" header="Estabelecimento" bodyStyle="text-align: center")
                        template(#body='props')
                            p {{ props.data.estabelecimento ? props.data.estabelecimento.nm_estabelecimento : '-'}}
                    Column(headerStyle="width: 20%;" field="medico" header="Especialista" bodyStyle="text-align: center")
                        template(#body='props')
                            p {{ props.data.medico ? props.data.medico.nm_medico : '-'}}
                    Column(headerStyle="width: 20%;" field="id" header="Paciente" bodyStyle="text-align: center")
                        template(#body='props')
                            p {{ props.data.horario ? props.data.horario.nm_paciente : '-'}}
                    Column(headerStyle="width: 20%;" field="ds_resposta" header="Comentário" bodyStyle="text-align: center")

                Paginator(
                    :rows="paginator.per_page"
                    :first="(paginator.page - 1) * paginator.per_page"
                    :totalRecords="paginator.count"
                    @page="onPage($event)"
                )

</template>

<style lang="scss">
.nps-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .border-selected-avaliacao {
        border: none;
        &.raiva {
            background-color: #FE565F; 
            :hover{
                background-color: #FE565F;
            }
        }
        &.ansiedade {
            background-color: #FF8446;
        }
        &.alegria {
            background-color: #FDD018;
        }
        &.inveja {
            background-color: #9ED872;
        }
        &.nojinho {
            background-color: #64CA64;
        }
        
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
    }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 23px;
        height: 23px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #94c860;
        border: 1px solid #84b553;
        transition: 250ms;
        cursor: pointer;
        padding-right:10px;
        &:hover { background-color: #84b553; }
        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &:hover { background-color: #b33030; }
        }
        .jam {
            color: #fff;
            margin-left:2px;
        }
    }
    .progress-small {
        width: 25px;
        height: auto;
    }
}
</style>

<script>
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import InputMask from "primevue/inputmask"
    import InputText from "primevue/inputtext"
    import Paginator from "primevue/paginator"
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from "primevue/progressspinner"
    import Tooltip from "primevue/tooltip"
    import Dropdown from 'primevue/dropdown'
    import Calendar from 'primevue/calendar'
    import { pCalendarLocale_ptbr } from './../../utils'
    import wsConfigs from "@/middleware/configs"
    import { Especialista, PesquisaAvaliacao, Estabelecimento } from './../../middleware'
    import _ from 'lodash'
    import moment from 'moment'

    export default {
        components: { Button, Column, DataTable, DataView, InputText,
            InputMask, Paginator, Panel, ProgressBar, ProgressSpinner, Dropdown, Calendar },
        directives: { tooltip: Tooltip },
        created() {
            this.getEstabelecimentos()
            this.getEspecialistas()
            this.applyFilters()
        },
        data() {
            return {
                list: [],
                avaliacao_geral: [],
                waiting: false,
                waitingStatus: [],
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                filters: {
                    cd_especialista: null,
                    cd_estabelecimento: null,
                    ie_tipo: null,
                    nr_avaliacao: null,
                    dt_criado_inicio: null,
                    dt_criado_fim: null,
                },
                options: {
                    especialistas: [],
                    estabelecimento: [],
                    tipo: [
                        {
                            value: 'N',
                            label: 'Nota'
                        },
                        {
                            value: 'C',
                            label: 'Comentário'
                        }
                    ],
                    tipo_avaliado: [
                        {
                            value: 'ME',
                            label: 'Médico'
                        },
                        {
                            value: 'ES',
                            label: 'Estabelecimento'
                        },
                        {
                            value: 'NA',
                            label: 'Não aplicável'
                        }
                    ],
                    nota: [
                        {
                            value: '1',
                            label: '1'
                        },
                        {
                            value: '2',
                            label: '2'
                        },
                        {
                            value: '3',
                            label: '3'
                        },
                        {
                            value: '4',
                            label: '4'
                        },
                        {
                            value: '5',
                            label: '5'
                        }
                    ],

                },
                waitingFilters: {
                    especialistas: false,
                    estabelecimento: false,
                },
                desabilitarId: 0,
                paginator: {
                    count: 0,
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                }
            }
        },
        methods: {
            getEspecialistas () {
                this.waitingFilters.especialistas = true
                Especialista.getAll().then(response => {
                    console.log(response)
                    if(response.status == 200) {
                        
                        response.data.forEach(especialista => {
                            this.options.especialistas.push({
                                label: especialista.nm_especialista,
                                value: especialista.id
                            })
                        })
                        this.waitingFilters.especialistas = false
                    }
                })
            },
            getEstabelecimentos () {
                this.waitingFilters.estabelecimento = true
                Estabelecimento.findAll().then(response => {
                    this.waitingFilters.estabelecimento = false
                    if (response.status === 200) {
                        this.options.estabelecimentos = response.data.map(e => {
                            return { text: e.nm_fantasia, value: e.id }
                        })
                        this.options.estabelecimentos.unshift({ text: 'TODOS', value: null })
                    }
                })
            },
            getList(params) {
                this.waiting = true
                PesquisaAvaliacao.findAll(params).then(response => {
                    this.waiting = false
                    if (response.status === 200) {
                        this.avaliacao_geral = response.data.avaliacao_por_qtd
                        this.avaliacao_geral =  _.orderBy(this.avaliacao_geral, ['nr_avaliacao'], ['asc'])
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                        this.list.forEach(avaliacao => {
                            avaliacao.dt_agenda_f = moment(avaliacao.horario.dt_agenda).format('DD/MM/YYYY')
                        })
                    }
                })

            },
            // desabilitar(data) {
            //     console.log(data);
            // },
            applyFilters (page) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) {
                    this.$router.replace( { query: { pg: this.paginator.page } } )
                }
                if(this.filters.dt_criado_inicio) params['dt_criado_inicio'] = moment(this.filters.dt_criado_inicio).format('YYYY-MM-DD')
                if(this.filters.dt_criado_fim) params['dt_criado_fim'] = moment(this.filters.dt_criado_fim).format('YYYY-MM-DD')
                if(this.filters.ie_tipo)  params['ie_tipo'] = this.filters.ie_tipo
                if(this.filters.ie_tipo_avaliado)  params['ie_tipo_avaliado'] = this.filters.ie_tipo_avaliado
                if(this.filters.cd_especialista)  params['cd_especialista'] = this.filters.cd_especialista
                if(this.filters.cd_estabelecimento)  params['cd_estabelecimento'] = this.filters.cd_estabelecimento
                if(this.filters.nr_avaliacao)  params['nr_avaliacao'] = this.filters.nr_avaliacao
                

                this.getList(params)
            },
            clearFilters() {
                this.filters = {
                    especialista: null,
                    estabelecimento: null,
                    ie_tipo: null,
                    dt_criado_inicio: null,
                    dt_criado_fim: null,
                },
                this.applyFilters()
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
        }
    }
</script>
